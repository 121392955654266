.OCVPageExternalDiv {
  background-color: #000;
  height: 400px;
}
.OCVPageColOne {
  padding-bottom: 30px;
  margin-bottom: 15px;
}
.OCVPageImage {
  display: block;
  overflow: hidden;
  max-width: 100%;
  margin: auto;
}
.OCVPageColTwo {
  padding-top: 50px;
  margin-bottom: 40px;
}
.OCVPagePaper {
  padding: 20px;
  margin-bottom: 15px;
}
.OCVPageDiv {
  padding-bottom: 30px;
}
